.form-bar {
    display: flex;
    padding: 10px 0px;
    justify-content: flex-end;
}

.bar-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 8px;
}

.scrollable-area {
    height: calc(100%);
    flex: 1;
    overflow-y: auto;
    padding-bottom: 20px;
}