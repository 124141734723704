.landsPresentation {
    display: flex;
    // align-items: center;
    margin-right: 100px;

    .fullLand {
        width: 420px;
        display: flex;
        flex-direction: row;
        height: 120px;
        background-color: #fff;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0px 3px 6px #00000029;
        border: 2px solid #FFF;

    }

    .greenHouseItemPres {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-left: 2px solid #FFF;
    }

    .greenHouseArea {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .remainingLandEmpty {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
    }
}

.fieldsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;


    .greenhouseUnit {}

    .ant-form-item {
        margin: 0;

        .ant-input-number {
            width: 200px;
        }
    }
}


.greenhouseColor {
    display: flex;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    // margin-right: 20px;
}

.addGreenhouseBtn {
    display: flex;
    align-items: center;
    gap: 5px;
    background: #fff;
    color: #00c66b;

    .anticon-plus {
        background-color: #00c66b;
        border-radius: 3px;
        padding: 3px;

        svg {
            color: #fff;
        }

    }

    .addBtnLabel {
        color: #9F8787;
    }
}