.LandsProfileDashboard {
  display: flex;

  .Sidebar {
    width: 210px;
    background-color: #fff;
    min-height: 100vh;
    box-shadow: -7px 0px 6px #00000026;

    .UserDetails {
      padding: 10px;

      .Avatar {
        text-align: center;
      }

      .Name {
        font: normal normal bold 23px/31px Myriad Arabic;
        letter-spacing: 0px;
        color: #25213B;
        opacity: 1;
      }

      .Location {
        text-align: right;
        font: normal normal normal 15px/23px Vazirmatn;
        letter-spacing: 0px;
        color: #9F8787;
        opacity: 1;
      }

      .Email {
        text-align: right;
        letter-spacing: 0.82px;
        color: #6E6893;
        opacity: 1;
      }

      .Tags {
        text-align: right;
        font: normal normal normal 13px/18px Vazirmatn;
        letter-spacing: 0px;
        color: #4D32F4;
        opacity: 1;
      }
    }

    .Menu {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 5px;

          button {
            background: #F4F2FF;
            color: #9F8787;
            outline: none;
            box-shadow: none;
            border: none;
            display: block;
            width: 100%;
            font: normal normal bold 15px/22px Vazirmatn;
            letter-spacing: 0px;
            color: #131313;
            text-align: left;
            padding: 10px;
            cursor: pointer;
            transition: 0.2s all;

            &:hover {
              background-color: #B4F4DD;
            }

            &.selected {
              background-color: #B4F4DD;
            }
          }
        }
      }
    }
  }

  .MainContent {
    width: calc(100% - 210px);
    padding: 30px;
  }
}
