.content-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.form-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}