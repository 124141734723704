.WajedModal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 100%;
    box-sizing: border-box;
    padding-right: 200px;
    padding-top: 65px;

    background-color: rgba(0, 0, 0, 0.4);
    /* Adjust opacity as needed (0.4 - 0.7 is common) */
    z-index: 2;
}



.WajedModal.visible {
    opacity: 1;
}

.WajedModal.fadeIn {
    animation: fadeIn 0.5s ease-in-out;
}

.WajedModal.fadeOut {
    animation: fadeOut 0.5s ease-in-out;
}

.WajedModal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}

.WajedModal-body {
    // position: relative;
    z-index: 1;
    background-color: #f4f4f4;

    width: calc(100vw - 200px);
    position: absolute;
    top: 65px;
}

.WajedModal-body__Content {
    // overflow-y: auto;
    min-height: calc(100vh - 65px);
    max-height: calc(100vh - 100px);
    padding-bottom: 2rem;
}

.Scroll__Enabled {
    overflow-y: auto;
}

// .WajedModal-body__Content > div {
//     max-height: 100%;
// }

// .WajedModal-body__Content .MainContent {

// }

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.WajedModal-body__Header {
    display: flex;
    align-items: center;
    background-color: #3970B1;

    .Header__First {
        display: flex;
        align-items: center;
        width: 210px;
        background-color: #3E97FF;
        color: #000;

        &.not_title {
            width: initial;

            button {
                margin: 0;
            }
        }

        button {
            height: 50px;
            width: 50px;
            color: #fff;
            background-color: #2160C6;
            margin-left: 10px;
            cursor: pointer;
            box-shadow: none;
            outline: none;
            border: none;
            //background-image: url('your-image-url.jpg'); /* Replace 'your-image-url.jpg' with the actual path to your image */
            background-size: cover;
            /* Adjust as needed */
            background-position: center;
            /* Adjust as needed */

            * {
                color: #fff;
            }
        }
    }

    .Header__Second {
        width: calc(100% - 210px);
        padding: 0 20px;
        color: #fff;
    }

    .Header__Float {
        position: relative;
        width: calc(100% - 210px);
        padding: 10px;
        color: #ffffff;
        background-color: rgba(51, 51, 51, 0);
        border-bottom: 2px solid #555555;
        top: 50px;
        display: flex;
        justify-content: space-between;
        /* Align items to the start and end of the container */
    }

    .LeftButtons {
        display: flex;
    }

    .LeftButtons button {
        margin-right: 10px;
        /* Adjust the spacing between left buttons */
    }

    .RightButtons button {
        margin-left: 10px;
        /* Adjust the spacing between right buttons */
    }

    .RightButtons img {
        width: 30px;
        /* Adjust the width of the image button */
    }
}

.hasModalOnTop {
    .WajedModal-body__Header {
        background-color: #ADB2B9;

        .Header__First {
            background-color: #BFC3C9;

            button {
                background-color: #62676F;
            }
        }
    }
}