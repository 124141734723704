.CropStepsHeader {
    display: flex;
    border-top: 2px solid #3E97FF;
    border-bottom: 2px solid #3E97FF;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin-bottom: 2rem;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    padding-left: 2rem;

}