.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Override the transition duration for the Drawer */
.ant-drawer-wrapper {
    transition: none !important;
    transform: translateX(0px) !important;
    overflow: auto;
}

.ant-drawer-content-wrapper {
    transform: translateX(0px) !important;
    overflow: auto;
}

.ant-slide-up-appear,
.ant-slide-up-enter,
.ant-slide-up-leave {
    animation-duration: 0s !important;
}

.ant-drawer-body {
    /* Add additional styles for the drawer body when it's open */
    /* For example, you can set the background color */
    background-color: #ffffff;
    animation-duration: 0s !important;
    transition: none !important;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

.ant-table-tbody {
    padding: 0 10px !important;
}

.ant-table-tbody>tr>td {
    padding: 4px !important;
}