.Nav {
    display: flex;

    >a {
        text-align: center;
        font-size: 17px;
        letter-spacing: 0;
        color: #3E97FF;
        opacity: 1;
        margin-left: 15px;
        border-bottom: 3px solid #3E97FF;
        padding: 5px 10px;

        &.IsActive {
            font-weight: 700;
        }
    }
}
