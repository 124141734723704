.SideBar {
    max-height: 100vh;
    border-right: 0;

    .ant-menu {
        background-color: #131313 !important;
    }
    .ant-menu-submenu-title{
        padding-right: 16px !important;

    }
    .ant-menu-item {
        padding-right: 36px !important;
    }

    .ant-menu-submenu-title, .ant-menu-item {
        color: #9F8787 !important;
    }

    


    .ant-menu-submenu-selected .ant-menu-submenu-title {
        background-color: transparent !important;
        color: #FFFFFF !important;
        
    } 
    .ant-menu-item-selected {
        background-color: transparent !important;
        color: #FFFFFF !important;
    }
    
}

.ContentWrapper {
    margin: 0;
    height: calc(100vh - 64px - 15); // 64px is the height of the header and 30px is the padding
    overflow-y:hidden ;

    & .ant-table {
        overflow: auto;
        // max-height: 70vh;
    }

}

  